import { VideoModalStore } from '@/compnents/videoModal';
import { FILE_TYPE, OPUS_TYPE, RIGHT_TABS } from '@/pages/anchorDetail/constants';
import { PlatformAccountModalStore } from '@/pages/anchorDetail/platformAccountModal';
import type { AnchorDetail, LiveItem } from '@/pages/anchorDetail/types';
import { type BaseData, type DictItem, EpUpload, copyToClipboard, getWxUrl, request } from '@/utils';
import getImgUrl from '@/utils/firstFrame';
import { ExclamationOutlined } from '@ant-design/icons';
import type { FormInstance } from 'antd';
import { Modal, message } from 'antd';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import * as imageConversion from 'image-conversion';
import { action, computed, observable } from 'mobx';
import React from 'react';
import { MyModelCardModel } from '../section/sectionAdmin/components/modelCard';
import { AuthModalStore } from './authModal';
import { CategoryModalStore } from './categoryModal';
import { EndService, EndServiceModal } from './endService';
import { RecommendWordModalStore } from './recommendWordModal';
import { SliceModalStore } from './sliceModalStore';
import { TagModalStore } from './tagModal';

export interface AnchorDetailProps {
  anchorDetailStore: AnchorDetailStore;
}

class SliceAndImgStore {
  constructor(private parent: AnchorDetailStore) {}
  public recommendWordModalStore = new RecommendWordModalStore();
  @computed public get sliceList() {
    return this.parent.anchorDetail?.userOpusListVO?.sliceList || [];
  }
  @computed public get selectedSliceList() {
    return this.sliceList.filter((item) => item._checked);
  }

  @computed public get isSliceListSelectedAll() {
    return this.selectedSliceList.length > 0 && this.selectedSliceList.length === this.sliceList.length;
  }
  @action public handleSliceListChooseAll = () => {
    const isSliceListSelectedAll = this.isSliceListSelectedAll;
    this.sliceList.forEach((item) => {
      item._checked = !isSliceListSelectedAll;
    });
  };

  @computed public get imageList() {
    return this.parent.anchorDetail?.userOpusListVO?.imageList || [];
  }
  @computed public get selectedImageList() {
    return this.imageList.filter((item) => item._checked);
  }
  @computed public get isImageListSelectedAll() {
    return this.selectedImageList.length > 0 && this.selectedImageList.length === this.imageList.length;
  }
  @action public handleImageListChooseAll = () => {
    const isImageListSelectedAll = this.isImageListSelectedAll;
    this.imageList.forEach((item) => {
      item._checked = !isImageListSelectedAll;
    });
  };

  @computed public get muckList() {
    return this.parent.anchorDetail?.userOpusListVO?.muckUpList || [];
  }
  @computed public get selectedMuckList() {
    return this.muckList.filter((item) => item._checked);
  }
  @computed public get isMuckListSelectedAll() {
    return this.selectedMuckList.length > 0 && this.selectedMuckList.length === this.muckList.length;
  }
  @action public handleMuckListChooseAll = () => {
    const isMuckListSelectedAll = this.isMuckListSelectedAll;
    this.muckList.forEach((item) => {
      item._checked = !isMuckListSelectedAll;
    });
  };

  private isUploadSliceOrImg = false;
  @action public handleUploadSliceOrImg = async (item: any, opusType: number, fileType: number) => {
    if (this.isUploadSliceOrImg) {
      return;
    }

    if (opusType === OPUS_TYPE.works.value && this.sliceList.length >= 10) {
      const error = '最多上传10个切片';
      message.warning({ key: error, content: error });
      return;
    }
    if (opusType === OPUS_TYPE.image.value && this.imageList.length >= 20) {
      const error = '最多上传20个形象照片';
      message.warning({ key: error, content: error });
      return;
    }
    if (opusType === OPUS_TYPE.muck.value && this.muckList.length >= 3) {
      const error = '最多上传3个模卡';
      message.warning({ key: error, content: error });
      return;
    }

    this.isUploadSliceOrImg = true;
    const loadingKey = '上传中...';
    message.loading({ key: loadingKey, content: loadingKey });
    try {
      const file = (await imageConversion.compressAccurately(item.file, {
        accuracy: 0.8,
        size: opusType === OPUS_TYPE.works.value ? 1024 * 30 : 1024,
      })) as File;

      const fileUrl = await EpUpload.singleUpload({
        attachmentType: fileType === FILE_TYPE.video.value ? FILE_TYPE.video.value : FILE_TYPE.image.value,
        module: 'opus',
        file: file,
      });

      let coverUrl = fileUrl;
      if (opusType === OPUS_TYPE.works.value) {
        const coverFile = await getImgUrl(fileUrl);
        coverUrl = await EpUpload.singleUpload({
          attachmentType: FILE_TYPE.image.value,
          module: 'opus',
          file: coverFile,
        });
      }

      await request({
        url: '/pbb/platform/anchor/opusAdd',
        method: 'post',
        data: {
          coverUrl,
          fileType,
          fileUrl,
          opusType,
          userId: this.parent.anchorDetail.id,
        },
      });

      await this.parent.getAnchorDetail();
    } finally {
      message.destroy(loadingKey);
      this.isUploadSliceOrImg = false;
    }
  };

  public handleDeleteSliceOrImg = (id: unknown) => {
    Modal.confirm({
      centered: true,
      title: '确定删除吗?',
      onOk: async () => {
        await request({
          url: '/pbb/platform/anchor/opusDelete',
          method: 'post',
          data: { id, userId: this.parent.anchorDetail.id },
        });
        await this.parent.getAnchorDetail();
      },
    });
  };

  public handleAuthSliceOrImg = (userOpusIdList: unknown[], title: string) => {
    if (userOpusIdList.length <= 0) {
      const error = '请选择要操作的数据';
      message.warning({ key: error, content: error });
      return;
    }

    this.parent.authModalStore.handleOpen({
      title,
      callback: async (params) => {
        await request({
          url: '/pbb/platform/anchor/opus/audit/operation',
          method: 'post',
          data: { userOpusIdList, ...params },
        });
        await this.parent.getAnchorDetail();
        message.success('操作成功');
      },
    });
  };

  public handleSliceRecommend = (ids: unknown[], butlerReview = '') => {
    if (ids.length <= 0) {
      const error = '请选择要操作的数据';
      message.warning({ key: error, content: error });
      return;
    }

    this.recommendWordModalStore.handleOpen({
      butlerReview,
      callback: async (params) => {
        await request({
          url: '/pbb/platform/sys/update/butler/review',
          method: 'post',
          data: { butlerReview: params.butlerReview, opusId: ids },
        });

        await this.parent.getAnchorDetail();
        message.success('操作成功');
      },
    });
  };
}

class VitaStore {
  constructor(private parent: AnchorDetailStore) {}
  @observable public isUploadVita = false;
  public handleUploadVita = async (item: any) => {
    if (this.isUploadVita) {
      return;
    }

    this.isUploadVita = true;
    try {
      const fileUrl = await EpUpload.singleUpload({
        attachmentType: FILE_TYPE.vita.value,
        module: 'opus',
        file: item.file,
      });

      await request({
        url: '/pbb/platform/anchor/vitaAdd',
        method: 'post',
        data: {
          title: item.file.name,
          url: fileUrl,
          userId: this.parent.anchorDetail.id,
        },
      });
      await this.parent.getAnchorDetail();
    } finally {
      this.isUploadVita = false;
    }
  };

  public handleDeleteVita = (id: unknown) => {
    Modal.confirm({
      centered: true,
      title: '确定删除吗?',
      onOk: async () => {
        await request({
          url: '/pbb/platform/anchor/vitaDelete',
          method: 'post',
          data: { id },
        });
        await this.parent.getAnchorDetail();
      },
    });
  };
}

export class AnchorDetailStore {
  constructor(
    private anchorId: string,
    public type: string,
  ) {}
  public sliceAndImgStore = new SliceAndImgStore(this);
  public vitaStore = new VitaStore(this);
  public myModelCardStore = new MyModelCardModel(); //
  public endServiceStore = new EndServiceModal(this);

  public authModalStore = new AuthModalStore();
  public flatAuthStore = new AuthModalStore({
    callback: async (params) => {
      await request({
        url: '/pbb/platform/anchor/vita/audit/operation',
        method: 'post',
        data: {
          isAuditUserAllInfo: 0,
          userId: this.anchorDetail.id,
          ...params,
        },
      });
      await this.getAnchorDetail();
      message.success('操作成功');
    },
  });

  public videoModalStore = new VideoModalStore();
  public tagModalStore = new TagModalStore();
  public sliceModalStore = new SliceModalStore();
  public categoryModalStore = new CategoryModalStore();
  public platformAccountModalStore = new PlatformAccountModalStore();

  @observable.ref public vitaAuditReasonList: DictItem[] = [];
  public getDictionary = async () => {
    const info = await request<BaseData<Record<'vita_audit_refuse_reason', DictItem[]>>>({
      url: '/pbb/platform/anon/system/sysDict/getDictMap',
      method: 'post',
      data: { dictTypeList: ['vita_audit_refuse_reason'] },
    });
    this.vitaAuditReasonList = info.data?.vita_audit_refuse_reason || [];
  };

  @observable public isEdit = false;
  @action public toggleIsEdit = () => {
    this.isEdit = !this.isEdit;
  };

  @observable public anchorDetail: AnchorDetail = {};

  public changeCanOwnInvite = async (id) => {
    this.anchorDetail.canOwnInvite = this.anchorDetail.canOwnInvite === 1 ? 0 : 1;
    await request({
      url: '/pbb/platform/anchor/canOwnInvite/batchUpdate',
      method: 'post',
      data: { userIdList: Array.of(id), canOwnInvite: this.anchorDetail.canOwnInvite },
    });
    await this.getAnchorDetail();
    message.success('保存成功');
  };

  public getAnchorDetail = async () => {
    const info = await request<BaseData<AnchorDetail>>({
      url: '/pbb/platform/anchor/detail',
      method: 'post',
      data: { id: this.anchorId },
    });

    this.anchorDetail = info.data || {};
  };

  public resumeFormInstance = React.createRef<FormInstance>();

  @observable public isResumeSave = false;
  public handleResumeSave = async () => {
    if (this.isResumeSave) {
      return;
    }

    const values = await this.resumeFormInstance.current.validateFields();
    const oldLiveTypeId = values.oldLiveTypeList || [];
    const newValues = {
      ...values,
      birthday: values.birthday ? dayjs(values.birthday).format('YYYY-MM-DD') : null,
      userId: this.anchorDetail.id,
      oldLiveTypeList: this.liveData
        .map((item) => ({
          ...item,
          childrenList: (item.childrenList || []).filter((val) => oldLiveTypeId.includes(`${val.id}`)),
        }))
        .filter((item) => oldLiveTypeId.includes(`${item.id}`) || item.childrenList?.length > 0),
    };

    this.isResumeSave = true;
    try {
      await request({ url: '/pbb/platform/anchor/info/update', method: 'post', data: newValues });
      await this.getAnchorDetail();
      message.success('保存成功');
    } finally {
      this.isResumeSave = false;
    }
  };

  @observable public activeTabs = RIGHT_TABS.resume.value;
  @action setActiveTabs = (activeTabs: string) => {
    if (activeTabs !== this.activeTabs) {
      this.activeTabs = activeTabs;
    }
  };

  public handleAddSlice = (libId, liveTypeId, liveTypeName) => {
    this.sliceModalStore.handleOpen({
      libId,
      liveTypeId,
      liveTypeName,
      title: '切片类目',
      callback: async (params) => {
        await request({
          url: '/pbb/platform/anchor/opus/update',
          method: 'post',
          data: {
            id: libId,
            liveTypeId: params.liveTypeId.value,
            liveTypeName: params.liveTypeId.label,
          },
        });
        message.success('添加成功');
        await this.getAnchorDetail();
      },
    });
  };

  public handleAddTag = (libId: unknown[], bizId: unknown[], categoryType: number, isMultiple = false) => {
    if (bizId.length <= 0) {
      const error = '请选择要添加的标签';
      message.warning({ key: error, content: error });
      return;
    }

    this.tagModalStore.handleOpen({
      categoryType,
      libId: libId,
      callback: async (params) => {
        await request({
          url: isMultiple ? '/pbb/platform/sys/tag/relation/add/batch' : '/pbb/platform/sys/tag/relation/save/batch',
          method: 'post',
          data: {
            bizId: bizId.join(','),
            categoryType,
            libId: params.libId.join(','),
          },
        });
        message.success('添加成功');
        await this.getAnchorDetail();
      },
    });
  };

  public handleCategory = (libId: unknown[], bizId: unknown[], categoryType: number, isMultiple = false) => {
    if (bizId.length <= 0) {
      const error = '请选择要添加的标签';
      message.warning({ key: error, content: error });
      return;
    }

    this.categoryModalStore.handleOpen({
      categoryType,
      libId: libId,
      callback: async (params) => {
        await request({
          url: isMultiple ? '/pbb/platform/sys/tag/relation/add/batch' : '/pbb/platform/sys/tag/relation/save/batch',
          method: 'post',
          data: {
            bizId: bizId.join(','),
            categoryType,
            libId: params.libId.join(','),
          },
        });
        message.success('添加成功');
        await this.getAnchorDetail();
      },
    });
  };

  @action public shareAnchor = (id: number) => {
    getWxUrl({
      channel: 6,
      anchorId: id,
    }).then((url) => {
      copyToClipboard(url);
      message.success('复制分享主播链接成功');
    });
  };
  public handleAuthAllResume = () => {
    this.authModalStore.handleOpen({
      header: (
        <div className={'p-4 flex items-center bg-warning/10 rounded-1 mb-4'}>
          <ExclamationOutlined className={'text-warning text-12'} />
          <span className={'text-warning text-12'}>
            注意：是审核主播简历全部信息，包括切片、形象照、模卡、文本信息全部一起审核
          </span>
        </div>
      ),
      title: '审核主播完整简历',
      callback: async (params) => {
        await request({
          url: '/pbb/platform/anchor/vita/audit/operation',
          method: 'post',
          data: {
            isAuditUserAllInfo: 1,
            userId: this.anchorDetail.id,
            ...params,
          },
        });
        await this.getAnchorDetail();
        message.success('操作成功');
      },
    });
  };

  @observable public liveData: LiveItem[] = [];
  public getLiveData = async () => {
    if (this.liveData.length) {
      return;
    }

    const info = await request<BaseData<LiveItem[]>>({
      url: '/pbb/platform/anon/system/liveType/list',
      method: 'POST',
    });
    this.liveData = info.data || [];
  };

  public handleAddPlatformAccount = () => {
    this.platformAccountModalStore.handleOpen({
      platformAccountInfo: this.anchorDetail?.userJobHuntVO?.platformAccountInfo || [],
      callback: async (params) => {
        await request({
          url: '/pbb/platform/anchor/info/update',
          method: 'post',
          data: { ...params, userId: this.anchorDetail.id },
        });
        await this.getAnchorDetail();
        message.success('保存成功');
      },
    });
  };
  /** 下载模卡 */
  @action public onModelCardDownload = async (item: AnchorDetail) => {
    try {
      if (item?.muckUpUrl) {
        console.log(item.muckUpUrl);
        const fileExt = item.muckUpUrl.split('.').pop();
        FileSaver.saveAs(item.muckUpUrl, `主播模卡-${item.name}.${fileExt}`);
      } else {
        if (item) {
          await this.myModelCardStore.getWxCode(String(item.id));
          this.myModelCardStore.setUserData(item);
          setTimeout(() => {
            this.myModelCardStore.downPic(`主播模卡-${item.name}`);
          }, 10);
        } else {
          message.error('获取模卡失败');
        }
      }

      // await
    } catch (error) {
      throw new Error();
    }
  };
}
