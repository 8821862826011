import { API } from '@/pages/anchor/anchorManagement/api.ts';
import type { UserVO } from '@/pages/anchor/anchorManagement/type.ts';
import type { SysTagCategoryVO, SysTagLibVO } from '@/pages/tagsManagement/type.ts';
import { type BaseData, type PaginationData, request } from '@/utils';
import { api } from '@/utils/api.ts';
import { message } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import { action, observable } from 'mobx';
interface Option {
  id?: string | number | null;
  name: React.ReactNode;
  children?: Option[];
  isLeaf?: boolean;
}

export class AnchorTagsModalStore {
  @observable public visible = false;

  @observable public formRef: FormInstance;
  @observable public userVo: UserVO[] = null;
  @observable public categories: Option[] = []; //标签分类列表
  @observable public tags = []; //标签列表
  @observable public selectedOptions = [];
  private parent = null;
  constructor(parent) {
    this.parent = parent;
  }
  @action
  getCategories = async () => {
    //主播标签
    const categoriesList = await request<BaseData<Option[]>>({
      url: api.tag.getAllLibList,
      method: 'get',
      params: { categoryType: 5 },
    });

    this.categories = categoriesList.data.filter((e) => e.children.length > 0);
  };

  @action
  setRef = (ref: FormInstance) => {
    this.formRef = ref;
  };
  @action
  onChange = (value: any, selectOptions: Option[]) => {
    const selected = selectOptions.map((item) => {
      return item[1];
    });
    this.selectedOptions = selected;
  };
  @action
  onTagDelete = (value: string) => {
    this.selectedOptions = this.selectedOptions.filter((item) => item.value !== value);
    let selectedTags = this.formRef.getFieldValue('libId');
    selectedTags = selectedTags.filter((e) => e[1] !== value);
    this.formRef.setFieldValue('libId', selectedTags);
  };
  @action
  onShow = async (item: UserVO[]) => {
    if (item.length === 0) {
      return;
    }
    await this.getCategories();
    this.userVo = item;
    if (item.length === 1) {
      const tags = item[0].tagList?.map((e) => {
        return [e.categoryId, e.libId];
      });
      this.selectedOptions = item[0].tagList.map((item) => {
        return { id: item.libId, name: item.libName };
      });
      setTimeout(() => {
        this.formRef.setFieldsValue({
          libId: tags,
        });
      }, 0);
    }
    this.visible = true;
  };

  @action
  onClose = () => {
    this.formRef.resetFields();
    this.selectedOptions = [];
    this.categories = [];
    this.visible = false;
  };

  @action
  onSubmit = async () => {
    const isValidate = await this.formRef.validateFields();
    if (!isValidate) {
      return '';
    }
    const values = this.formRef.getFieldValue('libId');
    const isBatch = this.userVo.length >= 1;
    const bizId = this.userVo.map((item) => item.id).join(',');
    const libId = values.map((el) => el[1]).join(',');

    const libIdLength = libId.split(',').length;
    if (libIdLength > 10) {
      return message.warning('标签不能超过10个');
    }

    return request({
      url: '/pbb/platform/sys/tag/relation/save/batch',
      method: 'post',
      data: { bizId, libId, categoryType: 5 },
    }).then(() => {
      message.success('标签设置成功');
      this.onClose();
      this.parent?.mainStore.grid.onRefresh();
    });
  };
}
