import { type BaseData, request, transformKeys } from '@/utils';
import { Form, type FormInstance, Modal, TreeSelect } from 'antd';
import type { BaseOptionType } from 'antd/es/select';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

interface Options {
  callback?: (params, ...args: any[]) => Promise<any>;
  title?: string;
  categoryType?: number;
  libId?: unknown[];
  liveTypeId?: number;
  liveTypeName?: string;
}

export class SliceModalStore {
  public formRef = React.createRef<FormInstance>();
  @observable public sliceData: [];
  private getTagData = async () => {
    const info = await request<BaseData<any>>({
      url: '/pbb/platform/anon/system/liveType/list',
      method: 'post',
      data: { pageNum: 1, pageSize: 1000 },
    });

    this.sliceData = transformKeys(info.data, {
      name: 'label',
      id: 'value',
      childrenList: 'children',
    });
    this.sliceData.forEach((item: BaseOptionType) => {
      item.disabled = true;
    });
  };

  @observable public open = false;
  @observable.ref options: Options = {};
  @action public handleOpen = (options: Options) => {
    this.open = true;
    this.options = options;
    this.getTagData();
  };
  @action public handleClose = () => {
    this.open = false;
    this.options = {};
  };

  @observable public isSubmit = false;
  public handleSubmit = async () => {
    const values = await this.formRef.current.validateFields();

    this.isSubmit = true;
    try {
      await this.options.callback(values);
      this.handleClose();
    } finally {
      this.isSubmit = false;
    }
  };
}

export const SliceModal = observer(
  ({
    sliceModalStore: { open, handleClose, options, handleSubmit, isSubmit, formRef, sliceData },
  }: { sliceModalStore: SliceModalStore }) => {
    return (
      <Modal
        destroyOnClose={true}
        centered={true}
        open={open}
        width={400}
        onCancel={handleClose}
        maskClosable={false}
        title=""
        closable={true}
        confirmLoading={isSubmit}
        onOk={handleSubmit}
      >
        <Form
          ref={formRef}
          layout={'vertical'}
          autoComplete="off"
          initialValues={{ liveTypeId: [options?.liveTypeId, options?.liveTypeName] }}
        >
          <Form.Item
            label="类目"
            name="liveTypeId"
            rules={[{ required: true, message: '请选择直播类目' }]}
          >
            <TreeSelect
              allowClear={true}
              treeCheckable={false}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              placeholder={'请选择标签'}
              treeNodeFilterProp="label"
              showSearch={true}
              treeData={sliceData}
              labelInValue={true}
              maxTagCount={10}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);
